import { graphql } from 'gatsby';
import React from 'react';

import { BuildByPeople, Culture, History, Layout, Partnership, StartOurStory } from '../components';
import SEO from '../components/seo';

export const query = graphql`
  query {
    Hand: file(relativePath: { eq: "hand.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    Building: file(relativePath: { eq: "building.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    Couple: file(relativePath: { eq: "couple.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    Flag: file(relativePath: { eq: "flag.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    Target: file(relativePath: { eq: "target.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    Telescope: file(relativePath: { eq: "telescope.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const NossaHistoria = ({ data }) => (
  <Layout internal={true} displayImobsParceiras={false}>
    <SEO title="Nossa História" />
    <StartOurStory data={data} />
    <Partnership data={data} />
    <History />
    <BuildByPeople data={data} />
    <Culture data={data} />
  </Layout>
);

export default NossaHistoria;
